import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import rehypeReact from "rehype-react"
import ModelViewer from "../components/ModelViewer"

// import Slider from "react-slick";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

class BlogPostTemplate extends React.Component {
  render() {
    const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: { "my-model-viewer": ModelViewer},
      // components: { "my-model-viewer": ModelViewer, "my-slider": Slider },
    }).Compiler

    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          keywords={[
            `Parametric`,
            `Wall Sculpture`,
            `Contemporary Art`,
            `Dimensional Wall Art`,
            `3D Wall Art`,
            `Modern Art`,
            `Flower Art`,
            `Mosaic Art`,
            `Wood Wall Art`,
            `Sacred Geometry`,
            `Abstract Art`,
            `Wall Object`,
            `3D Printing`,
            `Mandala Art`,
            `Geometric Art`,
            `Trippy Art`,
          ]}
        />
        <article
          className={`post-content ${post.frontmatter.thumbnail || `no-image`}`}
        >
          <header className="post-content-header">
            <h1 className="post-content-title">{post.frontmatter.title}</h1>
          </header>

          {post.frontmatter.description && (
            <p className="post-content-excerpt">
              {post.frontmatter.description}
            </p>
          )}

          {post.frontmatter.thumbnail && (
            <div className="post-content-image">
              <GatsbyImage
                className="kg-image"
                image={post.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
                alt={post.frontmatter.title}
              />
            </div>
          )}

          {renderAst(post.htmlAst)}

          {/* <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: post.html }}
          /> */}

          <footer className="post-content-footer">
            {/* There are two options for how we display the byline/author-info.
        If the post has more than one author, we load a specific template
        from includes/byline-multiple.hbs, otherwise, we just use the
        default byline. */}
          </footer>
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        thumbnail {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1360)
          }
        }
      }
    }
  }
`
